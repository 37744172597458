import { dashboardDataInterface } from './modules/DashboardModule/interface';
import { DIVISION } from './types/division';
import { FIELD } from './types/field';

export const FIELDCONST: FIELD[] = [
  {
    name: 'Academy',
    code: 'academy',
    description:
      'Academy adalah program pembelajaran yang dirancang untuk memberikan pemahaman dan keterampilan di bidang tertentu, seperti Data Science, Software Engineering, User Experience, dan Product Management. Program ini mencakup pemaparan materi dari mentor atau praktisi industri serta praktik langsung melalui latihan atau proyek, sehingga peserta tidak hanya memahami teori tetapi juga siap menghadapi tantangan di dunia kerja.',
    criteria:
      'Kalau kamu tertarik buat belajar langsung dari para ahli dan dapetin pengalaman praktis di bidang yang kamu minati, Academy bisa jadi tempat yang pas buat meng-upgrade skill kamu!',
    jobDescription: [
      'Bertanggung jawab atas proses recruitment staff Academy (UXA/SEA/DSA/PMA)',
      'Membangun dan menjaga hubungan internal antar divisi',
      'Membantu dan mengawasi tim Academy dalam menjalankan aktivitasnya',
      'Bertanggung jawab atas program kerja Human Resources Development',
    ],
    hrdContact: {
      name: 'Anindya Nabila Syifa',
      photo: 'academy-anindya-nabila-syifa.jpg',
      line: 'https://line.me/ti/p/anindyaa.ns',
      whatsapp: 'https://wa.me/62081296489697',
    },
    managerContact: {
      name: 'Academy Manager',
      photo: 'https://via.placeholder.com/150',
      line: 'https://line.me/ti/p/reiyamyuuzcw',
      whatsapp: 'https://wa.me/62092936035564',
    },
    images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg'],
    divisions: [],
    calendlyLink: 'https://calendly.com/anindya031205/interview-hrd-academy',
  },
  {
    name: 'Competition',
    code: 'competition',
    description:
      'Competition adalah ajang kompetisi yang dirancang untuk menguji dan mengembangkan keterampilan di berbagai bidang, seperti Business-IT, Cyber Security, Competitive Programming, Game Development, Artificial Intelligence, dan Data Analytics. Dalam kompetisi ini, peserta akan menghadapi tantangan nyata, menyusun strategi, dan mengasah kemampuan mereka melalui pengalaman kompetitif serta bimbingan dari para ahli.',
    criteria:
      'Kalau kamu ingin terlibat dalam pengalaman seru mengelola dan menyelenggarakan kompetisi yang menantang serta berdampak, ini bisa jadi kesempatan yang tepat buat kamu!',
    jobDescription: [
      'Bertanggung jawab atas proses recruitment staf Competition (CPC, CTF, DAD, BIZZIT, AIC, IGI)',
      'Membangun dan menjaga hubungan internal antar divisi',
      'Membantu dan mengawasi tim Competition dalam menjalankan aktivitasnya',
      'Bertanggung jawab atas program kerja Human Resources Development',
    ],
    hrdContact: {
      name: 'Danniel',
      photo: 'competition-danniel.jpeg',
      line: 'https://line.me/ti/p/catfishbstrd',
      whatsapp: 'https://wa.me/62082136390957',
    },
    managerContact: {
      name: 'Competition Manager',
      photo: 'https://via.placeholder.com/150',
      line: 'https://line.me/ti/p/reiyamyuuzcw',
      whatsapp: 'https://wa.me/62092936035564',
    },
    images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg'],
    divisions: [],
    calendlyLink: 'https://calendly.com/angdanniel8/interview-hrd',
  },
  {
    name: 'Event',
    code: 'event',
    description:
      'Divisi Event adalah tim yang berperan dalam merancang dan menjalankan berbagai acara, mulai dari seminar, hiburan, hingga aktivitas interaktif yang melibatkan peserta dan partner. Setiap divisi punya fokusnya sendiri, seperti memberikan edukasi teknologi, menghadirkan pengalaman seru, atau membuka ruang eksplorasi bagi audiens.',
    criteria:
      'Kalau kamu suka mengatur acara, bekerja sama dengan banyak pihak, dan ingin bikin pengalaman yang berkesan untuk peserta, divisi event bisa jadi tempat yang pas buat kamu!',
    jobDescription: [
      'Bertanggung jawab atas proses recruitment staff Marketing (Seminar/Playground/Stage)',
      'Membangun dan menjaga hubungan internal antar divisi',
      'Membantu dan mengawasi tim Event dalam menjalankan aktivitasnya',
      'Bertanggung jawab atas program kerja Human Resources Development',
    ],
    hrdContact: {
      name: 'Gnade Yuka',
      photo: 'event-gnade-yuka.JPG',
      line: 'https://line.me/ti/p/yukaagnd',
      whatsapp: 'https://wa.me/62085706843325',
    },
    managerContact: {
      name: 'Event Manager',
      photo: 'https://via.placeholder.com/150',
      line: 'https://line.me/ti/p/reiyamyuuzcw',
      whatsapp: 'https://wa.me/62092936035564',
    },
    images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg'],
    divisions: [],
    calendlyLink: 'https://calendly.com/conflatekei/30min',
  },
  {
    name: 'Finance',
    code: 'finance',
    description:
      'Finance adalah divisi yang bertanggung jawab dalam mengelola keuangan COMPFEST 17, mulai dari perencanaan anggaran, pencatatan transaksi, hingga pengelolaan pemasukan dan pengeluaran agar seluruh kegiatan dapat berjalan dengan lancar. Divisi ini memastikan setiap aspek finansial terkontrol dengan baik dan transparan.',
    criteria:
      'Kalau kamu tertarik dengan pengelolaan keuangan, perencanaan anggaran, dan ingin memastikan jalannya acara tetap stabil dari sisi finansial, divisi finance bisa jadi tempat yang tepat buat kamu!',
    jobDescription: [
      'Bertanggung jawab atas proses recruitment staff Finance',
      'Membangun dan menjaga hubungan internal antar divisi',
      'Membantu dan mengawasi tim Finance dalam menjalankan aktivitasnya',
      'Bertanggung jawab atas program kerja Human Resources Development',
    ],
    hrdContact: {
      name: 'Agus Tini Sridewi',
      photo: 'finance-agus-tini-sridewi.jpg',
      line: 'https://line.me/ti/p/himuggles.',
      whatsapp: 'https://wa.me/62087776444748',
    },
    managerContact: {
      name: 'Finance Manager',
      photo: 'https://via.placeholder.com/150',
      line: 'https://line.me/ti/p/reiyamyuuzcw',
      whatsapp: 'https://wa.me/62092936035564',
    },
    images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg'],
    divisions: [],
    calendlyLink: 'https://calendly.com/agustinisridewi05/30min',
  },
  {
    name: 'Marketing',
    code: 'marketing',
    description:
      'Marketing adalah divisi yang bertugas membangun citra dan menyebarkan informasi tentang COMPFEST 17 lewat berbagai cara, seperti konten kreatif, media sosial, strategi digital, dan copywriting. Dari bikin postingan menarik sampai nulis artikel dan press release, divisi ini memastikan COMPFEST dikenal luas dan bisa menjangkau lebih banyak orang.',
    criteria:
      'Kalau kamu suka bikin konten, menyusun strategi komunikasi, atau pengen belajar lebih dalam soal pemasaran digital, divisi marketing bisa jadi tempat yang pas buat kamu!',
    jobDescription: [
      'Bertanggung jawab atas proses recruitment staff Marketing (Editorial/Direct/Digital)',
      'Membangun dan menjaga hubungan internal antar divisi',
      'Membantu dan mengawasi tim Marketing dalam menjalankan aktivitasnya',
      'Bertanggung jawab atas program kerja Human Resources Development',
    ],
    hrdContact: {
      name: 'Maira Shasmeen Mazaya',
      photo: 'marketing-maira-shasmeen.PNG',
      line: 'https://line.me/ti/p/081315000206',
      whatsapp: 'https://wa.me/62081315000206',
    },
    managerContact: {
      name: 'Marketing Manager',
      photo: 'https://via.placeholder.com/150',
      line: 'https://line.me/ti/p/reiyamyuuzcw',
      whatsapp: 'https://wa.me/62092936035564',
    },
    images: ['1.JPG', '2.JPG', '3.JPG', '4.JPG', '5.JPG', '6.JPG'],
    divisions: [],
    calendlyLink: 'https://calendly.com/maira-shasmeen/compfest-oprec',
  },
  {
    name: 'Partnership',
    code: 'partnership',
    description:
      'Partnership adalah divisi yang bertanggung jawab dalam mencari, menjalin, dan menjaga hubungan kerja sama dengan berbagai pihak, seperti media partner dan sponsor, untuk mendukung kelancaran COMPFEST 17. Divisi ini berperan dalam meningkatkan awareness melalui publikasi media serta mengamankan dukungan finansial dan sumber daya dari sponsor. Dengan strategi yang tepat, partnership memastikan COMPFEST mendapat eksposur luas dan dukungan yang dibutuhkan.',
    criteria:
      'Kalau kamu tertarik membangun relasi, melakukan negosiasi, dan bekerja sama dengan berbagai pihak untuk mendukung kesuksesan acara, divisi partnership bisa jadi tempat yang tepat buat kamu!',
    jobDescription: [
      'Bertanggung jawab atas proses recruitment staff Partnership (Sponsorship dan media partner)',
      'Membangun dan menjaga hubungan internal antar divisi',
      'Membantu dan mengawasi tim Partnership dalam menjalankan aktivitasnya',
      'Bertanggung jawab atas program kerja Human Resources Development',
    ],
    hrdContact: {
      name: 'Salomo Immanuel Putra',
      photo: 'partnership-salomo-immanuel-putra.JPG',
      line: 'https://line.me/ti/p/salakoa',
      whatsapp: 'https://wa.me/6208551494964',
    },
    managerContact: {
      name: 'Partnership Manager',
      photo: 'https://via.placeholder.com/150',
      line: 'https://line.me/ti/p/reiyamyuuzcw',
      whatsapp: 'https://wa.me/62092936035564',
    },
    images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg'],
    divisions: [],
    calendlyLink: 'https://calendly.com/salomo-i-p-manullang/hr',
  },
  {
    name: 'Operational',
    code: 'operational',
    description:
      'Operational adalah divisi yang bertanggung jawab dalam memastikan segala aspek teknis dan logistik COMPFEST 17 berjalan lancar. Mulai dari pengelolaan perlengkapan dan keamanan, penyediaan venue dan transportasi, hingga pengadaan konsumsi untuk seluruh pihak yang terlibat. Divisi ini memastikan setiap detail operasional terkoordinasi dengan baik agar acara bisa berlangsung dengan nyaman dan sesuai rencana.',
    criteria:
      'Kalau kamu suka mengatur logistik, memastikan segala sesuatu berjalan sesuai jadwal, dan ingin terlibat langsung dalam kelancaran acara, divisi operational bisa jadi pilihan yang tepat buat kamu!',
    jobDescription: [
      'Bertanggung jawab atas proses recruitment staf Operasional (Equipment & Security, Transportation & Venue, Food & Beverages)',
      'Membangun dan menjaga hubungan internal antar divisi',
      'Membantu dan mengawasi tim Operasional dalam menjalankan aktivitasnya',
      'Bertanggung jawab atas program kerja Human Resources Development',
    ],
    hrdContact: {
      name: 'Muhammad Aldo Fahrezy',
      photo: 'operational-aldo.png',
      line: 'https://line.me/ti/p/donaadd',
      whatsapp: 'https://wa.me/62082268700050',
    },
    managerContact: {
      name: 'Operational Manager',
      photo: 'https://via.placeholder.com/150',
      line: 'https://line.me/ti/p/reiyamyuuzcw',
      whatsapp: 'https://wa.me/62092936035564',
    },
    images: ['1.jpg', '2.jpg'],
    divisions: [],
    calendlyLink:
      'https://calendly.com/muhammad-aldo43-ui/open-recruitment-hr-compfest-17-2025',
  },
  {
    name: 'Creative',
    code: 'creative',
    description:
      'Creative adalah divisi yang bertanggung jawab dalam menciptakan tampilan visual dan dokumentasi untuk COMPFEST 17, mulai dari desain grafis, dekorasi acara, hingga pembuatan video dan animasi. Divisi ini berperan dalam mengemas informasi dengan cara yang menarik dan estetik, serta memastikan setiap momen penting dalam COMPFEST terdokumentasi dengan baik.',
    criteria:
      'Kalau kamu suka desain, dekorasi, fotografi, videografi, atau ingin mengasah kreativitas dalam menghasilkan karya visual, divisi Creative bisa jadi tempat yang pas buat kamu!',
    jobDescription: [
      'Bertanggung jawab atas proses recruitment staf Creative (VisDes, Decoration, DnC)',
      'Membangun dan menjaga hubungan internal antar divisi',
      'Membantu dan mengawasi tim Creative dalam menjalankan aktivitasnya',
      'Bertanggung jawab atas program kerja Human Resources Development',
    ],
    hrdContact: {
      name: 'Deanita Sekar Kinasih',
      photo: 'creative-deanita-sekar-kinasih.jpg',
      line: 'https://line.me/ti/p/jsk26',
      whatsapp: 'https://wa.me/62089603024277',
    },
    managerContact: {
      name: 'Creative Manager',
      photo: 'https://via.placeholder.com/150',
      line: 'https://line.me/ti/p/reiyamyuuzcw',
      whatsapp: 'https://wa.me/62092936035564',
    },
    images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg', '7.jpg'],
    divisions: [],
    calendlyLink: 'https://calendly.com/deanitasekar/wawancara-hr',
  },
  {
    name: 'IT Dev',
    code: 'itdev',
    description:
      'IT Development adalah divisi yang bertanggung jawab dalam pengembangan teknologi untuk COMPFEST 17, mulai dari membangun website hingga merancang pengalaman pengguna yang nyaman dan menarik. Di divisi ini, Software Engineer fokus mengembangkan sistem, sementara tim UI/UX memastikan tampilan dan interaksi webnya optimal.',
    criteria:
      'Kalau kamu suka ngoding, desain UI/UX, atau pengen ikut bikin teknologi yang dipakai di COMPFEST, divisi IT Development bisa jadi tempat yang pas buat kamu!',
    jobDescription: [
      'Membondingkan staff itdev, menjadi notulen saat rapat, jika mampu bisa bantu sprint, membantu proses oprec staff itdev',
    ],
    hrdContact: {
      name: 'Ari Darrell Muljono',
      photo: 'it-development-ari-darrell-muljono.jpg',
      line: 'https://line.me/ti/p/dar-rell',
      whatsapp: 'https://wa.me/62087787497831',
    },
    managerContact: {
      name: 'IT Development Manager',
      photo: 'https://via.placeholder.com/150',
      line: 'https://line.me/ti/p/reiyamyuuzcw',
      whatsapp: 'https://wa.me/62092936035564',
    },
    images: ['1.jpeg', '2.jpeg', '3.jpg', '4.jpg', '5.jpg'],
    divisions: [],
    calendlyLink: 'https://calendly.com/aridarrell30/interviu-hrd-itdev',
  },
];

export const DIVISIONCONST: DIVISION[] = [
  {
    name: 'Data Science Academy',
    description:
      'Data Science Academy adalah rangkaian acara yang berperan sebagai wadah bagi mahasiswa yang memiliki minat di bidang data science untuk mengembangkan pengetahuan dan keterampilan mereka melalui pemaparan materi dan pengaplikasian data science di dunia industri',
    jobdesc: [
      'Mencari profesional di bidang Data Science untuk menjadi speaker, mentor, dan juri DSA',
      'Menjadi Liaison Officer (LO) dari dan mempersiapkan kebutuhan speaker, mentor, dan juri DSA',
      'Melakukan seleksi terhadap dan menjadi LO peserta DSA',
      'Memastikan acara DSA berjalan dengan lancar',
    ],
    code: 'dsa',
    calendlyUrl: 'https://linktr.ee/keya12',
    picContactName: 'Ali Akbar Murthadha',
    picContactPhoto: 'https://via.placeholder.com/150',
    taskFileUrl:
      'https://drive.google.com/drive/folders/1efqtefzukP5SaeaUc-X1Ujopv0V7n0c9',
    images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg'],
    field: FIELDCONST[0],
  },
  {
    name: 'Software Engineer Academy',
    description:
      'Software Engineering Academy adalah serangkaian acara yang bertujuan untuk mengembangkan pemahaman peserta di bidang Software Engineering serta membekali mereka dengan skill yang dapat mereka terapkan untuk dapat bersaing di dunia industri.',
    jobdesc: [
      'Mencari software engineer yang telah bekerja di industri teknologi untuk menjadi speaker, mentor, juri, dan interviewer SEA',
      'Melakukan seleksi dan wawancara peserta SEA',
      'Mempersiapkan rangkaian kegiatan SEA serta memastikan kelancarannya',
      'Menjadi Liason Officeer (LO) bagi peserta SEA',
    ],
    code: 'sea',
    calendlyUrl: 'https://calendly.com/qintharaadya/interview_sea',
    picContactName: 'Qinthara Adya Kurniawan',
    picContactPhoto: 'https://via.placeholder.com/150',
    taskFileUrl:
      'https://docs.google.com/document/d/1zHvDenASNS_xo336NpyZsi9zE4IaRtR29hEcUIO2M5E',
    images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg'],
    field: FIELDCONST[0],
  },
  {
    name: 'User Experience Academy',
    description:
      'User Experience Academy adalah rangkaian kegiatan yang memperkenalkan dasar-dasar User Experience (UX), dari UX research hingga UX design, dan melengkapi peserta dengan keterampilan praktikal untuk tantangan UX di dunia nyata.',
    jobdesc: [
      'Mempersiapkan kebutuhan kurikulum, speaker, dan mentor UXA',
      'Ikut serta dalam mempersiapkan acara camp UXA',
      'Melakukan seleksi dan menjadi LO bagi peserta UXA',
      'Memastikan rangkaian acara UXA berjalan dengan lancar',
    ],
    code: 'uxa',
    calendlyUrl: 'https://calendly.com/nazahwa7/interview-staff-uxa',
    picContactName: 'Naila Zahwa',
    picContactPhoto: 'https://via.placeholder.com/150',
    taskFileUrl:
      'https://docs.google.com/document/d/1uYZIZvAa01QO1qAG5NJiiJ8B_hvruWBR_GZnTruLHqY',
    images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg'],
    field: FIELDCONST[0],
  },
  {
    name: 'Product Management Academy',
    description:
      'Product Management Academy adalah rangkaian acara yang bertujuan untuk mengembangkan pengetahuan dan keahlian peserta yang memiliki minat di bidang Product Management. Pemaparan materi dilakukan pada peserta oleh speaker dan mentor tepercaya yang akan mempersiapkan peserta untuk bersaing di dunia kerja.',
    jobdesc: [
      'Mencari experts di bidang Product Management untuk menjadi speaker dan mentor',
      'Melakukan seleksi pada calon peserta',
      'Menjadi LO bagi para peserta PMA',
      'Memastikan seluruh rangkaian acara PMA berjalan lancar',
    ],
    code: 'pma',
    calendlyUrl: 'https://calendly.com/nisrinaalyanabilah/interview-pma',
    picContactName: 'Nisrina Alya Nabilah',
    picContactPhoto: 'https://via.placeholder.com/150',
    taskFileUrl:
      'https://docs.google.com/document/d/1HGzAvM_xUOnyjLip_GlSMG11plkqsjXSLoV47S48TNI',
    images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg'],
    field: FIELDCONST[0],
  },
  {
    name: 'Business-IT Case',
    description:
      'Business-IT Case adalah kompetisi yang menantang peserta untuk dapat merumuskan solusi dari suatu kasus bisnis dengan memanfaatkan teknologi informasi.',
    jobdesc: [
      'Membantu merancang dan melaksanakan perlombaan BizzIT.',
      'Menjadi Liaison Officer, serta mencari juri dan mentor profesional di bidang Business IT.',
      'Mengurus proses administrasi dan menjadi narahubung dari perlombaan Business IT Case.',
      'Membuat, mencari, dan menilai case study dengan tema IT.',
    ],
    code: 'bizzit',
    calendlyUrl: 'https://calendly.com/levinaaurell14/interview-staff-bizzit',
    picContactName: 'Levina Aurellia',
    picContactPhoto: 'https://via.placeholder.com/150',
    taskFileUrl:
      'https://docs.google.com/document/d/1qqC5faEmzbWD34rZ-KkoQYSPDjgKyH7TTtYEdjB4jTk',
    images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg'],
    field: FIELDCONST[1],
  },
  {
    name: 'Capture the Flag',
    description:
      'Capture The Flag adalah kompetisi yang diadakan sebagai sarana untuk melatih kemampuan problem solving dalam bidang cyber security, dimana hal yang diuji merupakan proyeksi permasalahan yang sering terjadi dalam kehidupan nyata.',
    jobdesc: [
      'Merancang dan membuat soal CTF.',
      'Melakukan testing terhadap soal yang telah dibuat.',
      'Melakukan deployment platform kompetisi dan service soal.',
      'Mengelola server saat kompetisi berlangsung.',
    ],
    code: 'ctf',
    calendlyUrl: 'https://calendly.com/felesiaj26/interview-staff-ctf',
    picContactName: 'Felesia Junelus',
    picContactPhoto: 'https://via.placeholder.com/150',
    taskFileUrl:
      'https://docs.google.com/document/d/12JUa4-02yzDVJkjqMCNK0SOlyGmK6yyK_ZxhVcs-3B0',
    images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg'],
    field: FIELDCONST[1],
  },
  {
    name: 'Competitive Programming Contest',
    description:
      'Competitive Programming Contest adalah kompetisi yang menguji kemampuan berpikir komputasional. Pada kompetisi ini, peserta diminta untuk menyelesaikan soal-soal pemrograman dengan menulis program-program yang bisa mengeksekusi hal-hal yang diminta oleh soal.',
    jobdesc: [
      'Membantu mencari ide soal untuk CPC.',
      'Membuat testcase dan deskripsi soal untuk CPC.',
      'Meninjau soal CPC yang sudah ada.',
      'Melakukan testing pada soal CPC yang telah dibuat.',
    ],
    code: 'cpc',
    calendlyUrl: 'https://calendly.com/kevincornelius1905/interview-staff-cpc',
    picContactName: 'Kevin Cornellius Widjaja',
    picContactPhoto: 'https://via.placeholder.com/150',
    taskFileUrl:
      'https://docs.google.com/document/d/1rqCvzWEG1XXaNhaXNuI-iyaN19KEvcGjk40EDxyRosM',
    images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg'],
    field: FIELDCONST[1],
  },
  {
    name: 'Indie Game Ignite',
    description:
      'Indie Game Ignite adalah kompetisi yang menjadi sarana bagi para game developer untuk berkreasi dan mengembangkan kemampuannya dalam game development melalui pengalaman kompetitif dan sesi mentoring bersama para game developer ahli.',
    jobdesc: [
      'Menjadi LO, serta mencari juri, pembicara, dan mentor IGI',
      'Menilai indie game hasil karya peserta pada Game Jam dan babak penyisihan IGI',
      'Mengurus administrasi peserta dan menjadi LO peserta',
      'Merancang penilaian dan menyiapkan kebutuhan pelaksanaan Game Jam',
      'Merancang acara dan menyiapkan perlengkapan teknis IGI',
    ],
    code: 'igi',
    calendlyUrl: 'https://calendly.com/farrel0810/interview-staff-igi',
    picContactName: 'Farrel Arrayyan Adrianshah',
    picContactPhoto: 'https://via.placeholder.com/150',
    taskFileUrl:
      'https://docs.google.com/document/d/1GoMpcUiPE0Awd_WpH63pM5CctMp_2WR2OsDZj1P81iQ',
    images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg'],
    field: FIELDCONST[1],
  },
  {
    name: 'Artificial Intelligence Innovation Challenge',
    description:
      'AI Innovation Challenge (AIC) adalah sebuah platform yang memberikan kesempatan bagi para ahli dan sekaligus peminat dunia teknologi untuk menghasilkan kreasi inovatif, solutif, dan berdampak berbasis teknologi Artificial Intelligence. AIC juga merupakan tempat pengembangan teknologi AI untuk memecahkan masalah-masalah global dalam berbagai macam aspek. Tahun ini, AIC akan memberikan workshop untuk semua peserta dan juga sesi mentoring interaktif bagi para finalis.',
    jobdesc: [
      'Menyusun form penilaian dan memeriksa submisi peserta.',
      'Melakukan pendekatan serta menghubungi pembicara, mentor, dan juri yang ahli di bidang AI.',
      'Menjadi Liaison Officer bagi para pembicara, mentor, dan juri ketika sebelum suatu acara dimulai.',
      'Merancang rulebook lomba dan memvalidasi peserta yang mendaftar.',
      'Menyusun rundown dan memoderasi setiap rangkaian acara.',
    ],
    code: 'aic',
    calendlyUrl:
      'https://calendly.com/elizabethmeilanny/interview-staff-competition',
    picContactName: 'Elizabeth Meilanny Sitanggang',
    picContactPhoto: 'https://via.placeholder.com/150',
    taskFileUrl:
      'https://docs.google.com/document/d/1UMs2Sl12AgZL6BZXGERxvf-wEQ24yJQNTBb00i6yl-o',
    images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg'],
    field: FIELDCONST[1],
  },
  {
    name: 'Data Analytics Dash',
    description:
      'Data Analytics Dash (DAD) merupakan kompetisi yang menguji kemampuan peserta dalam mengolah dan memanipulasi data serta memvisualisasikan dan mencari insight dari suatu data. Kompetisi ini bertujuan untuk mempersiapkan para mahasiswa dalam beradaptasi ke karir seorang Data Analyst atau Business Intelligence Analyst yang handal.',
    jobdesc: [
      'Membantu proses perancangan dan pelaksanaan rangkaian kompetisi.',
      'Menjadi Liaison Officer yang bertugas sebagai narahubung untuk peserta, mentor, dan juri.',
      'Melakukan verifikasi berkas-berkas pendaftaran peserta.',
      'Mencari dan melakukan kerja sama dengan potential mentors dan judges yang merupakan ahli di bidang data.',
    ],
    code: 'dad',
    calendlyUrl: 'https://calendly.com/rafasoelistiono/interviewdad',
    picContactName: 'Rafa Rally Soelistiono',
    picContactPhoto: 'https://via.placeholder.com/150',
    taskFileUrl:
      'https://docs.google.com/document/d/1khAEFX95NSkbiqsuSFp80dkxXlMMiJnf-W6noW8ioXY',
    images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg'],
    field: FIELDCONST[1],
  },
  {
    name: 'Stage',
    description:
      'Divisi Stage adalah divisi yang bertujuan untuk memberikan edukasi terkait teknologi dan hiburan. Tujuan tersebut dicapai dengan melalui program kerja kami, yaitu merancang acara-acara dan berkolaborasi dengan Bidang lain.',
    jobdesc: [
      'Merancang TOR dan MOU untuk kerja sama dengan pihak eksternal.',
      'Melakukan concepting  untuk suatu acara',
      'Menjadi organisator dan eksekutor untuk acara-acara di Compfest ',
      'Melakukan approach kepada pembicara dan menjadi penghubung dengan Compfest.',
    ],
    code: 'stage',
    calendlyUrl: 'https://calendly.com/bilqismulyadi07/event-stage-interview',
    picContactName: 'Bilqis Nisrina Dzahabiyah Mulyadi',
    picContactPhoto: 'https://via.placeholder.com/150',
    taskFileUrl:
      'https://docs.google.com/document/d/1dj120Ca-076oDIDyOzlGijfWQeGJ68mqBJ4xmTyJGv0',
    images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg'],
    field: FIELDCONST[2],
  },
  {
    name: 'Seminar',
    description:
      'Divisi Seminar adalah divisi yang bertujuan untuk memberikan edukasi terkait perkembangan teknologi. Tujuan tersebut dicapai melalui beberapa program kerja dengan target audiens pelajar, mahasiswa, peminat IT, dan masyarakat luas.',
    jobdesc: [
      'Merancang perencanaan konsep dan TOR untuk program kerja Seminar',
      'Mengorganisasi persiapan dan penyelenggaraan program kerja Seminar',
      'Melakukan approach dan koordinasi dengan pembicara seminar sebagai Liaison Officer',
    ],
    code: 'seminar',
    calendlyUrl: 'https://calendly.com/crmla-g06/interview-staff-seminar',
    picContactName: 'Carmella Geraldine Sutrisna',
    picContactPhoto: 'https://via.placeholder.com/150',
    taskFileUrl:
      'https://docs.google.com/document/d/1Mzkd-inLZ9XeQCNUtb7Dranb6TeRiu0FftPYqJPJO44',
    images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg'],
    field: FIELDCONST[2],
  },
  {
    name: 'Playground',
    description:
      'Playground adalah divisi yang bertujuan untuk mewadahi audiens untuk melakukan eksplorasi terhadap perusahaan yang hadir sebagai exhibitor pada COMPFEST 17. Tujuan tersebut dicapai dengan beberapa kegiatan dimana perusahaan partner menyediakan konten dan penawaran menarik.',
    jobdesc: [
      'Membangun relasi dengan perusahaan eksternal sebagai Liaison Officer.',
      'Mengelola Playground at Main Event, yang menggabungkan Company Exhibition, Job Fair, dan Supermarket, dengan konsep gamifikasi.',
      'Mengembangkan Explore IT, termasuk pameran inovasi teknologi seperti VR Exhibition dan gadget interaktif lainnya.',
      'Mengatur regulasi dan mekanisme Virtual Playground.',
    ],
    code: 'playground',
    calendlyUrl:
      'https://calendly.com/tzkiaalyani/interview-compfest-playground',
    picContactName: 'Tazkia Nur Alyani ',
    picContactPhoto: 'https://via.placeholder.com/150',
    taskFileUrl:
      'https://docs.google.com/document/d/1NWeVtkZgkzr0ZG0j4a7qO8pXyNSO8lxDU66575-i5-M',
    images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg'],
    field: FIELDCONST[2],
  },
  {
    name: 'Business Development',
    description:
      'Business Development adalah divisi yang berperan dalam proses bisnis yang dilakukan pada COMPFEST 17, yaitu merencanakan, mengelola, serta mengeksekusi penjualan merchandise dan kerjasama IT Project. Business Development memiliki tujuan untuk dapat memberikan kesan yang baik bagi masyarakat terhadap COMPFEST 17 melalui produk dan jasa yang ditawarkan.',
    jobdesc: [
      'Divisi ini terbagi menjadi dua subdivisi, yaitu Merchandise dan IT Project',
      'Melakukan negosiasi harga dan kondisi pengiriman dengan vendor (Merchandise)',
      'Mendata dan merekap hasil penjualan serta inventaris produk merchandise By.Compfest. Melakukan negosiasi harga dan kondisi pengiriman dengan vendor (Merchandise)',
      'Bertanggung jawab atas perencanaan dan pengeksekusian konten media sosial By.Compfest. Melakukan negosiasi harga dan kondisi pengiriman dengan vendor (Merchandise)',
      'Bertanggung jawab dalam seluruh proses pembuatan proposal eksternal untuk penawaran jasa proyek IT (IT Project)',
      'Bertanggung jawab kepada client atas seluruh rangkaian pengerjaan proyek (IT Project)',
      'Mengelola seluruh pelaksanaan proyek (IT Project)',
    ],
    code: 'bd',
    calendlyUrl:
      'https://calendly.com/hamidah-p2512/interview-staff-business-development',
    picContactName: 'Putri Hamidah Riyanto',
    picContactPhoto: 'https://via.placeholder.com/150',
    taskFileUrl:
      'https://docs.google.com/document/d/1aCRn3-MDck41V51bURWSBzuTnIIAzy8mwn0A-eIQrl4',
    images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg'],
    field: FIELDCONST[3],
  },
  {
    name: 'Direct Marketing',
    description:
      'Divisi direct marketing adalah divisi yang bertujuan meningkatkan keterlibatan media sosial untuk Compfest dengan menciptakan konten kreatif dan inovatif di platform media sosial Compfest. DRM COMPFEST 17 akan fokus pada pembuatan konten yang bertujuan untuk pendidikan, meningkatkan keterlibatan media sosial, dan meningkatkan kesadaran merek.',
    jobdesc: [
      'Membuat, merencanakan, dan mengelola konten video untuk IG reels dan tiktok compfest yang bertujuan untuk SOFTSELLING compfest.',
      'Menentukan strategi konten dang mengikuti tren yang sedang berjalan.',
      'Menjadi MC di kegiatan dan program kerja compfest',
      'Menjalin kerjasama dengan pihak eksternal untuk mendukung promosi Compfest.',
      'Mencari relasi di kampus, fakultas, dan organisasi lain untuk mempromosikan compfest secara langsung',
    ],
    code: 'drm',
    calendlyUrl: 'https://calendly.com/eternistt/interview-staff-drm',
    picContactName: 'Raditya Zayyan Daffa',
    picContactPhoto: 'https://via.placeholder.com/150',
    taskFileUrl:
      'https://drive.google.com/drive/folders/1efqtefzukP5SaeaUc-X1Ujopv0V7n0c9',
    images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg'],
    field: FIELDCONST[4],
  },
  {
    name: 'Digital Marketing',
    description:
      'Digital Marketing merupakan divisi yang bertugas untuk memberikan dan menyebarkan seluruh informasi COMPFEST melalui platform digital serta menyusun kerangka publikasi yang akan diunggah.',
    jobdesc: [
      'Mengelola Sosial Media COMPFEST ',
      'Merancang dan membuat konten untuk meningkatkan engagement dari COMPFEST ',
      'Melakukan Live Report pada kegiatan yang terdapat di COMPFEST',
      'Melakukan approach kepada pihak eksternal untuk mempromosikan COMPFEST',
    ],
    code: 'dm',
    calendlyUrl: 'https://calendly.com/rinduaurellia6/30min',
    picContactName: 'Rindu Aurellia Zahra',
    picContactPhoto: 'https://via.placeholder.com/150',
    taskFileUrl: '',
    images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg'],
    field: FIELDCONST[4],
  },
  {
    name: 'Editorial Marketing',
    description:
      'Editorial marketing merupakan divisi pemasaran yang menekankan penggunaan tulisan sebagai sarana komunikasinya. Editorial Marketing memiliki fokus utama pada copywriting, kegiatan ini mencakup penyusunan teks seperti caption untuk konten media sosial dan website, newsletter, broadcast dan lain sebagainya. Selain itu, editorial marketing juga mencangkup pembuatan artikel press release, tulisan yang berhubungan dengan teknologi, dan topik lain yang relevan.',
    jobdesc: [
      'Menulis teks yang persuasif dan kreatif untuk berbagai keperluan pemasaran.',
      'Menghasilkan konten berkualitas tinggi yang sesuai dengan tujuan pemasaran',
      'Menyunting dan merevisi materi tulisan untuk memastikan keakuratan, konsistensi, dan kejelasan pesan.',
    ],
    code: 'em',
    calendlyUrl: 'https://calendly.com/baskararifqi/interview-staff-edm',
    picContactName: 'Rajendra Rifqi Baskara',
    picContactPhoto: 'https://via.placeholder.com/150',
    taskFileUrl: '',
    images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg'],
    field: FIELDCONST[4],
  },
  {
    name: 'Equipment & Security',
    description:
      'Equipment and Security adalah divisi yang bertanggung jawab atas perlengkapan dan peralatan yang diperlukan, serta memastikan kelancaran jalannya acara dan memberikan keamanan jalannya COMPFEST.',
    jobdesc: [
      'Mengawasi event offline dalam aspek keamanan dan perlengkapan',
      'Menjalin hubungan dengan partner kerja terkait keperluan perlengkapan',
      'Menyediakan perlengkapan yang dibutuhkan untuk COMPFEST',
    ],
    code: 'es',
    calendlyUrl: 'https://calendly.com/darrenms7120/interview-staff-cf-ens',
    picContactName: 'Darren Marcello Sidabutar',
    picContactPhoto: 'https://via.placeholder.com/150',
    taskFileUrl: '',
    images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg'],
    field: FIELDCONST[6],
  },
  {
    name: 'Transportation & Venue',
    description:
      'Transportation and Venue adalah divisi yang bertanggung jawab sebagai penyedia venue baik dari dalam maupun luar Fasilkom, mengurusi perizinan, dan transportasi manusia dan barang.',
    jobdesc: [
      'Mengurus transportasi barang ke venue COMPFEST',
      'Menyediakan alat transportasi untuk keperluan COMPFEST',
      'Memastikan kebutuhan transportasi dan venue terpenuhi',
    ],
    code: 'tv',
    calendlyUrl: 'https://calendly.com/fathan-alfahrezi-ui/interview',
    picContactName: 'Fathan Alfahrezi',
    picContactPhoto: 'https://via.placeholder.com/150',
    taskFileUrl: '',
    images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg'],
    field: FIELDCONST[6],
  },
  {
    name: 'Food & Beverage',
    description:
      'Food & Beverage merupakan divisi yang berkewajiban atas pengadaan dan pendistribusian konsumsi bagi semua pihak yang terlibat dalam acara COMPFEST 17. Divisi Food & Beverage juga bertanggung jawab dalam pelaksanaan food bazaar yang bekerjasama dengan pihak katering dan juga tenant.',
    jobdesc: [
      'Menyediakan & menjamin ketersediaan konsumsi selama acara COMPFEST 17 berlangsung.',
      'Menjalankan dan mengawasi distribusi konsumsi selama acara COMPFEST 17 berlangsung.',
      'Bertanggung jawab atas pelaksanaan food bazaar selama acara COMPFEST 17 berlangsung.',
      'Menjalin komunikasi dan bekerjasama dengan vendor, tenant dan katering selama acara COMPFEST 17.',
      'Membuat laporan pertanggungjawaban konsumsi setiap selesai susunan kegiatan.',
    ],
    code: 'fb',
    calendlyUrl:
      'https://calendly.com/tirahnurul51/interview-staff-food-beverages',
    picContactName: 'Satirah Nurul Fikriyyah',
    picContactPhoto: 'https://via.placeholder.com/150',
    taskFileUrl: '',
    images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg'],
    field: FIELDCONST[6],
  },
  {
    name: 'Media Partner',
    description:
      'Media Partner adalah divisi yang bertanggung jawab dalam mencari dan menjalin hubungan kerja sama dengan berbagai media partner untuk meningkatkan awareness dan menarik minat masyarakat terhadap rangkaian acara COMPFEST 17.',
    jobdesc: [
      'Mencari kontak calon media partner yang berpotensi untuk bekerja sama dengan COMPFEST 17.',
      'Melakukan approach, follow up, dan negosiasi dengan calon media partner.',
      'Menyusun MoU sesuai kesepakatan yang telah dicapai oleh kedua belah pihak, serta memenuhi seluruh tanggung jawab yang tertera.',
      'Menjadi liaison officer bagi media partner yang hadir di acara-acara COMPFEST 17.',
    ],
    code: 'mp',
    calendlyUrl: 'https://calendly.com/juma-jordan06/interview-partnership',
    picContactName: 'Juma Jordan Bimo',
    picContactPhoto: 'https://via.placeholder.com/150',
    taskFileUrl:
      'https://docs.google.com/document/d/1LN5ANHSzX-0RFiwFLnj8-_lco4e3tTTSLL4CIejcdvM',
    images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg'],
    field: FIELDCONST[5],
  },
  {
    name: 'Sponsorship',
    description:
      'Sponsorship adalah divisi yang berperan dalam melakukan pencarian partner, melakukan negosiasi untuk menjalin suatu kemitraan dan menjaga hubungan dengan berbagai partner melalui program sponsorship, dengan tujuan utama untuk mendukung finansial dan sumber daya yang dibutuhkan untuk kelancaran dan keberhasilan COMPFEST 17. Tidak hanya itu, jika kalian berhasil mendapatkan sponsor, kalian juga akan mendapatkan komisi lohhhh.',
    jobdesc: [
      'Menyusun proposal sponsorship dengan membuat valuasi, paket dan kontraprestasi Sponsorship pada proposal.',
      'Mencari serta menentukan target calon sponsor COMPFEST 17.',
      'Melakukan pendekatan (approach), Follow-up, dan negosiasi dengan calon sponsor.',
      'Membuat MoU sesuai dengan kesepakatan dengan calon sponsor sebagai tanda bukti kesepakatan sah antara COMPFEST 17 dengan pihak sponsor.',
      'Menjalin hubungan baik dengan pihak sponsor selama COMPFEST 17 dan memastikan pemenuhan seluruh tanggung jawab sesuai dengan yang diatur didalam MoU.',
    ],
    code: 'sponsorship',
    calendlyUrl: 'https://calendly.com/m-hafizhanan/interview-partnership',
    picContactName: 'Muhammad Hafiz Hanan',
    picContactPhoto: 'https://via.placeholder.com/150',
    taskFileUrl:
      'https://docs.google.com/document/d/1sNYulomCchZVi90cgw0MfEpP6UzQE3sBKHl8Z_0k278',
    images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg'],
    field: FIELDCONST[5],
  },
  {
    name: 'Software Engineering',
    description:
      'Divisi Software Engineer (SE) COMPFEST merupakan divisi yang bertanggung jawab dalam mengembangkan website COMPFEST yang akan digunakan untuk keberlangsungan acara COMPFEST. Anda diberikan kesempatan untuk menunjukkan keahlian kalian dalam merancang web yang inovatif dalam dalam sebuah kolaborasi antar anggota tim. Anda juga diharapkan dapat memberikan tren atau teknologi baru dalam pengembangan web COMPFEST nantinya.',
    jobdesc: [
      'Memberikan ide-ide terkait project yang sedang dikerjakan.',
      'Menyelesaikan task yang telah diberikan dengan baik. baik front-end maupun back-end',
      'Berpartisipasi dalam code review dan pull request.',
      'Mengikuti weekly syncup untuk mengabari progres pengerjaan task yang sedang dikerjakan.',
    ],
    code: 'se',
    calendlyUrl: 'https://calendly.com/derricksu1818/interview-staff-se',
    picContactName: 'Derrick',
    picContactPhoto: 'https://via.placeholder.com/150',
    taskFileUrl:
      'https://docs.google.com/document/d/1AoJsf_d9eFlayrGwc4ARd7KOWQCQIkZz0ldKYzs5MDc',
    images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg'],
    field: FIELDCONST[8],
  },
  {
    name: 'UI/UX',
    description:
      'UI/UX merupakan divisi yang bertanggung jawab dalam merancang desain antarmuka (interface) dan pengalaman pengguna yang optimal untuk website COMPFEST 17. Proses perancangan ini dimulai dari aspek visual hingga fungsionalitas, dengan tujuan utama untuk memberikan pengalaman yang menarik dan memuaskan bagi para pengguna yang berinteraksi dengan website COMPFEST 17.',
    jobdesc: [
      'Melakukan research mengenai desain yang akan dibuat serta merancang kerangka desain berupa wireframe',
      'Membuat prototype dan melakukan testing untuk memastikan pengalaman pengguna yang optimal',
      'Membangun dan mengembangkan design system dan menerapkannya pada website COMPFEST 17',
      'Belajar berkoordinasi dengan tim Software Engineer untuk mengintegrasikan desain UI/UX dengan fungsi teknis yang dibutuhkan',
      'Meningkatkan pemahaman dan berbagi pengetahuan dengan sesama anggota divisi UI/UX',
    ],
    code: 'uiux',
    calendlyUrl: 'https://calendly.com/ayshialafleur/interview-staff-ui-ux',
    picContactName: 'Ayshia La Fleur',
    picContactPhoto: 'https://via.placeholder.com/150',
    taskFileUrl:
      'https://docs.google.com/document/d/1eNOYxb4zMFSo6Iq6j3KUOBC6ZU8v1vOEqoYfJTGNSig',
    images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg'],
    field: FIELDCONST[8],
  },
  {
    name: 'Visual Design',
    description:
      'Visual Design merupakan divisi yang bertanggung jawab memenuhi kebutuhan desain grafis untuk publikasi maupun non publikasi COMPFEST 17. Visual Design bertugas mengemas informasi dan konten dalam bentuk yang estetis namun tetap dapat menyampaikan pesan dengan jelas.',
    jobdesc: [
      'Mendesain bahan publikasi maupun non-publikasi COMPFEST 17',
      'Mempertahankan branding visual yang telah ditetapkan oleh COMPFEST 17',
    ],
    code: 'vd',
    calendlyUrl: 'https://calendly.com/rheina-adinda',
    picContactName: 'Rheina Adinda Morani Sinurat',
    picContactPhoto: 'https://via.placeholder.com/150',
    taskFileUrl:
      'https://docs.google.com/document/d/1V88YgVNuqZniWjrZpD8o0HqBMYExycigole7v38dpEQ',
    images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg'],
    field: FIELDCONST[7],
  },
  {
    name: 'Decoration',
    description:
      'Decoration adalah divisi yang bertanggung jawab dalam menghidupkan COMPFEST 17 secara fisik. Dengan dekorasi yang menarik, acara COMPFEST 17 akan menciptakan kesan dan pengalaman yang tidak terlupakan bagi setiap elemen. Menjadi bagian dari Decoration dapat mengasah skill kreativitas dan logistikmu.',
    jobdesc: [
      'merealisasikan visi acara dari segi visual dan tata letak dekorasi',
      'Bertanggung jawab atas logistik dekorasi dari pembuatan material dekorasi hingga hari -H acara',
    ],
    code: 'decoration',
    calendlyUrl: 'https://calendly.com/khalisharose/interview-decoration-staff',
    picContactName: 'Khalisha Roselani Prabowo',
    picContactPhoto: 'https://via.placeholder.com/150',
    taskFileUrl:
      'https://docs.google.com/document/d/19NAb-Ba4rqEReqrm69P88Uf0Vlug0oyx1yQrl2fVa6U',
    images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg'],
    field: FIELDCONST[7],
  },
  {
    name: 'Documentation & Animation',
    description:
      'Documentation and Animation adalah Divisi yang bertugas untuk berdokumentasi kepada event event yang akan berlangsung sepanjang COMPFEST 17. selain kami ditugaskan sebagai tim dokumetasi kami juga mempunyai tugas sebagai Tim yang akan Membuat Video-Video yang akan di buat untuk COMPFEST 17 disini bisa belajar tentang video creating dan membuat animasi dan tampah skill baru baru.',
    jobdesc: [
      'Melakukan kegiatan Dokumentasi Saat event event Compfest berlangsung',
      'Melakukan Video editing untuk video video COMPFEST',
    ],
    code: 'da',
    calendlyUrl: 'https://calendly.com/rasyad-zulham-ui/interview-staff-dna',
    picContactName: 'Rasyad Zulham Rabani',
    picContactPhoto: 'https://via.placeholder.com/150',
    taskFileUrl:
      'https://docs.google.com/document/d/1EmnRDLywOuJ095UOq122oEpedL1WBFqgTk4JeyeDc7M',
    images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg'],
    field: FIELDCONST[7],
  },
];

export const DASHBOARDDIVISION: dashboardDataInterface[] = [
  {
    code: 'dsa',
    field: 'Academy',
    filenameCode: 'Data_Science_Academy',
    textColor: 'bg-academy-gradient',
    task: 'https://drive.google.com/drive/folders/1efqtefzukP5SaeaUc-X1Ujopv0V7n0c9?usp=sharing',
    divisionName: 'Data Science Academy',
  },
  {
    code: 'sea',
    field: 'Academy',
    filenameCode: 'Software_Engineering_Academy',
    textColor: 'bg-academy-gradient',
    task: 'https://docs.google.com/document/d/1zHvDenASNS_xo336NpyZsi9zE4IaRtR29hEcUIO2M5E/edit?usp=sharing',
    divisionName: 'Software Engineering Academy',
  },
  {
    code: 'uxa',
    field: 'Academy',
    filenameCode: 'User_Experience_Academy',
    textColor: 'bg-academy-gradient',
    task: 'https://docs.google.com/document/d/1uYZIZvAa01QO1qAG5NJiiJ8B_hvruWBR_GZnTruLHqY/edit?usp=sharing',
    divisionName: 'User Experience Academy',
  },
  {
    code: 'pma',
    field: 'Academy',
    filenameCode: 'Project_Management_Academy',
    textColor: 'bg-academy-gradient',
    task: 'https://docs.google.com/document/d/1HGzAvM_xUOnyjLip_GlSMG11plkqsjXSLoV47S48TNI/edit?usp=sharing',
    divisionName: 'Project Management Academy',
  },
  {
    code: 'bizzit',
    field: 'Competition',
    filenameCode: 'Business_IT_Case',
    textColor: 'bg-competition-gradient',
    task: 'https://docs.google.com/document/d/1qqC5faEmzbWD34rZ-KkoQYSPDjgKyH7TTtYEdjB4jTk/edit?usp=sharing',
    divisionName: 'Business-IT Case',
  },
  {
    code: 'ctf',
    field: 'Competition',
    filenameCode: 'Capture_the_Flag',
    textColor: 'bg-competition-gradient',
    task: 'https://docs.google.com/document/d/12JUa4-02yzDVJkjqMCNK0SOlyGmK6yyK_ZxhVcs-3B0/edit?usp=sharing',
    divisionName: 'Capture the Flag',
  },
  {
    code: 'cpc',
    field: 'Competition',
    filenameCode: 'Competitive_Programming_Contest',
    textColor: 'bg-competition-gradient',
    task: 'https://docs.google.com/document/d/1rqCvzWEG1XXaNhaXNuI-iyaN19KEvcGjk40EDxyRosM/edit?usp=drive_link',
    divisionName: 'Competitive Programming Contest',
  },
  {
    code: 'igi',
    field: 'Competition',
    filenameCode: 'Indie_Game_Ignite',
    textColor: 'bg-competition-gradient',
    task: 'https://docs.google.com/document/d/1GoMpcUiPE0Awd_WpH63pM5CctMp_2WR2OsDZj1P81iQ/edit?usp=sharing',
    divisionName: 'Indie Game Ignite',
  },
  {
    code: 'aic',
    field: 'Competition',
    filenameCode: 'Artificial_Intelligence_Innovation_Challenge',
    textColor: 'bg-competition-gradient',
    task: 'https://docs.google.com/document/d/1UMs2Sl12AgZL6BZXGERxvf-wEQ24yJQNTBb00i6yl-o/edit?usp=sharing',
    divisionName: 'Artificial Intelligence Innovation Challenge',
  },
  {
    code: 'dad',
    field: 'Competition',
    filenameCode: 'Data_Analytics_Dash',
    textColor: 'bg-competition-gradient',
    task: 'https://docs.google.com/document/d/1khAEFX95NSkbiqsuSFp80dkxXlMMiJnf-W6noW8ioXY/edit?usp=sharing',
    divisionName: 'Data Analytics Dash',
  },
  {
    code: 'stage',
    field: 'Event',
    filenameCode: 'Stage',
    textColor: 'bg-event-gradient',
    task: 'https://docs.google.com/document/d/1dj120Ca-076oDIDyOzlGijfWQeGJ68mqBJ4xmTyJGv0/edit?usp=sharing',
    divisionName: 'Stage',
  },
  {
    code: 'seminar',
    field: 'Event',
    filenameCode: 'Seminar',
    textColor: 'bg-event-gradient',
    task: 'https://docs.google.com/document/d/1Mzkd-inLZ9XeQCNUtb7Dranb6TeRiu0FftPYqJPJO44/edit?usp=sharing',
    divisionName: 'Seminar',
  },
  {
    code: 'playground',
    field: 'Event',
    filenameCode: 'Playground',
    textColor: 'bg-event-gradient',
    task: 'https://docs.google.com/document/d/1NWeVtkZgkzr0ZG0j4a7qO8pXyNSO8lxDU66575-i5-M/edit?usp=sharing',
    divisionName: 'Playground',
  },
  {
    code: 'bd',
    field: 'Finance',
    filenameCode: 'Business_Development',
    textColor: 'bg-finance-gradient',
    task: 'https://docs.google.com/document/d/1aCRn3-MDck41V51bURWSBzuTnIIAzy8mwn0A-eIQrl4/edit?usp=sharing',
    divisionName: 'Business Development',
  },
  {
    code: 'drm',
    field: 'Marketing',
    filenameCode: 'Direct_Marketing',
    textColor: 'bg-marketing-gradient',
    task: 'https://docs.google.com/document/d/1Lk_yoLBTbUIqDL4gdGQxKQCI1hSEch9v/edit?usp=sharing&ouid=102778909834025555898&rtpof=true&sd=true',
    divisionName: 'Direct Marketing',
  },
  {
    code: 'dm',
    field: 'Marketing',
    filenameCode: 'Digital_Marketing',
    textColor: 'bg-marketing-gradient',
    task: 'https://docs.google.com/document/d/1xk2EqLOGson0tpxp1ug6GEXEbHhugqD0oG3J4kuBWts/edit?usp=sharing',
    divisionName: 'Digital Marketing',
  },
  {
    code: 'em',
    field: 'Marketing',
    filenameCode: 'Editorial_Marketing',
    textColor: 'bg-marketing-gradient',
    task: 'https://docs.google.com/document/d/1zMJY0exxcVYbhoEmihVFd_vAt0srTdQHnctTHH-PhzU/edit?usp=sharing',
    divisionName: 'Editorial Marketing',
  },
  {
    code: 'es',
    field: 'Operational',
    filenameCode: 'Equipment_Security',
    textColor: 'bg-operational-gradient',
    task: 'https://docs.google.com/document/d/1B3PiOnXuyT6w3Xn-xEu_mdhCHOOtUxd3OnjhqiUJNDs/edit?tab=t.0',
    divisionName: 'Equipment & Security',
  },
  {
    code: 'tv',
    field: 'Operational',
    filenameCode: 'Transportation_Venue',
    textColor: 'bg-operational-gradient',
    task: 'https://drive.google.com/file/d/1Uox19aGoV6D7ioXXm7huMQccHOINloxH/view?usp=drive_link',
    divisionName: 'Transportation & Venue',
  },
  {
    code: 'fb',
    field: 'Operational',
    filenameCode: 'Food_Beverage',
    textColor: 'bg-operational-gradient',
    task: 'https://drive.google.com/file/d/1Fuk66f5YNGhm-QorAdycJnUtdXjbf1q1/view?usp=sharing',
    divisionName: 'Food & Beverage',
  },
  {
    code: 'mp',
    field: 'Partnership',
    filenameCode: 'Media_Partner',
    textColor: 'bg-partnership-gradient',
    task: 'https://docs.google.com/document/d/1LN5ANHSzX-0RFiwFLnj8-_lco4e3tTTSLL4CIejcdvM/edit?usp=sharing',
    divisionName: 'Media Partner',
  },
  {
    code: 'sponsorship',
    field: 'Partnership',
    filenameCode: 'Sponsorship',
    textColor: 'bg-partnership-gradient',
    task: 'https://docs.google.com/document/d/1sNYulomCchZVi90cgw0MfEpP6UzQE3sBKHl8Z_0k278/edit?usp=sharing',
    divisionName: 'Sponsorship',
  },
  {
    code: 'se',
    field: 'IT Dev',
    filenameCode: 'Software_Engineering',
    textColor: 'bg-itdev-gradient',
    task: 'https://docs.google.com/document/d/1AoJsf_d9eFlayrGwc4ARd7KOWQCQIkZz0ldKYzs5MDc/edit?usp=sharing',
    divisionName: 'Software Engineering',
  },
  {
    code: 'uiux',
    field: 'IT Dev',
    filenameCode: 'UI_UX',
    textColor: 'bg-itdev-gradient',
    task: 'https://docs.google.com/document/d/1eNOYxb4zMFSo6Iq6j3KUOBC6ZU8v1vOEqoYfJTGNSig/edit?usp=sharing',
    divisionName: 'UI/UX',
  },
  {
    code: 'vd',
    field: 'Creative',
    filenameCode: 'Visual_Design',
    textColor: 'bg-creative-gradient',
    task: 'https://docs.google.com/document/d/1V88YgVNuqZniWjrZpD8o0HqBMYExycigole7v38dpEQ/edit?usp=sharing',
    divisionName: 'Visual Design',
  },
  {
    code: 'decoration',
    field: 'Creative',
    filenameCode: 'Decoration',
    textColor: 'bg-creative-gradient',
    task: 'https://docs.google.com/document/d/19NAb-Ba4rqEReqrm69P88Uf0Vlug0oyx1yQrl2fVa6U/edit?usp=sharing',
    divisionName: 'Decoration',
  },
  {
    code: 'da',
    field: 'Creative',
    filenameCode: 'Documentation_Animation',
    textColor: 'bg-creative-gradient',
    task: 'https://docs.google.com/document/d/1EmnRDLywOuJ095UOq122oEpedL1WBFqgTk4JeyeDc7M/edit?usp=sharing',
    divisionName: 'Documentation & Animation',
  },
];
